import { UILayout } from 'bora-material-ui'
import React from 'react'
import { styles, xsStyles } from './Payment'
import useSWR from 'swr'
import { getTerms } from '../../../../../../../services/sales-static/api/confirmationTerms'
import { pathOr } from 'ramda'
import purify from 'dompurify'
import { getMaritimeStyle } from '../../../../../../../utils/maritimeStyleUtils'
import moduleStyles from './PaymentTerms.module.scss'

const getTermsBody = pathOr('', [0, 'body'])

const style = getComputedStyle(document.body)
const fontRegular = style.getPropertyValue('--font-regular')

export const PaymentTerms = ({ locale = 'en', sailPackageCode }) => {
  const { data, error } = useSWR(`confirmation-terms-${sailPackageCode}-${locale}`, () =>
    getTerms(locale, sailPackageCode)
  )

  const routeTerms = error ? '' : getTermsBody(data)

  const html = { __html: purify.sanitize(routeTerms, { ADD_ATTR: ['target'] }) }
  const sectionStyles = getMaritimeStyle({
    fontFamily: fontRegular,
    color: '#2D3955',
    lineHeight: '20px',
  })

  return (
    <UILayout
      data-testid="payment-terms"
      column
      color="#000000"
      childrenStyle={styles.termsChildren}
      sms={xsStyles.paymentTerms}
      className={moduleStyles.main}
    >
      <section
        className={moduleStyles.section}
        lang={locale}
        style={sectionStyles}
        dangerouslySetInnerHTML={html}
      ></section>
    </UILayout>
  )
}
