import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { add, compose, defaultTo, forEach, head, isEmpty, map, pathOr, reduce, reject } from 'ramda'
import CircularProgress from '@material-ui/core/CircularProgress'
import { UIIcon, UILayout, UIText } from 'bora-material-ui'
import { timeSort } from './AvailableTickets.util'
import { TicketRowHeader, Voyages } from './Layout'

import { actions, selectors, transformSailInventoriesToTickets } from '../../provider'
import messages from '../../../../consts/messages'
// check it
import { setEditedReservationData } from '../../../../services/reservation/actions/reservation.js'
import { setSelectedSailForEdit } from '../../../../services/schedule/actions/schedule'
import { selectedSailBySailPackageCodeWhileEdit } from '../../../../services/schedule/reducers'
import { THEME } from 'bora-material-ui/themes'
import { getSelectedRoute, getSelectedSailPackageFirstRoute } from '../../../../services/user-selections'
import { getStyle, isLiinilaevad } from '../../../../utils/liinilaevadThemeUtils'
import { getGavdosStyle } from '../../../../utils/gavdosStyleUtils'
import { isMaritime, isBluewave, getBluewaveStyle } from '../../../../utils/maritimeStyleUtils'
import moduleStyles from '../../../Modal/Modals.module.scss'
import cn from 'classnames'
import moment from 'moment'
import { getAttributeFromRoute } from '../../../../services/pricing/reducers/selectors'
import { notEmpty } from '../../../../utils/ramda-extend'

const capacityWarningNote = '*'
const noMoreLocalsTicketsNote = '**'

const getShouldShowNextDayDeparturesMessage = (voyages = []) => {
  const checkIsNextDayVoyage = pathOr(false, ['sail', 'isNextDay'])
  return voyages.some(checkIsNextDayVoyage)
}

const getWarnings = compose(
  defaultTo(['', '']),
  head,
  reject(isEmpty),
  map(pathOr([], ['inventory', 'inventories', 'warnings']))
)

// const filterBySalesClosed = (sailInventories = []) => {
//   return sailInventories.filter(({ sail: { salesClosed } = {} }) => !salesClosed)
// }

const xsStyles = {
  padding0MarginTop20: { padding: '0', margin: '20px 0 0 0' },
  marginVertical10: { margin: '10px 0' },
}

const styles = {
  warning: {
    padding: '15px 10px',
    width: 'auto',
    margin: '0 0 0 20px',
    borderBottom:
      window.brandProps.theme !== THEME.SAAREMAA &&
      window.brandProps.theme !== THEME.KIHNU &&
      !isMaritime &&
      !isLiinilaevad &&
      '1px solid #ddd',
  },

  smallStar: {
    margin: '0 5px 0 0',
    'font-size': '18px',
    'font-family': 'GinesoNorm',
    'font-weight': 'bold',
    color: '#d0021b',
  },

  mediumStar: {
    'font-size': '18px',
    'font-family': 'GinesoNormDem,Fjalla One',
    'font-weight': 'normal',
    color: '#f46e5a',
    margin: '0 5px 0 0',
  },

  bigStar: {
    'font-size': '28px',
  },
}

export class AvailableTickets extends React.PureComponent {
  // eslint-disable-next-line max-lines-per-function,complexity
  render() {
    const { muiTheme, intl } = this.context
    const {
      sailInventories,
      selectedSail,
      ticketsIsFetching,
      onEditMode,
      selectedRoute,
      warnings,
      getLocalsSoldOutAttribute,
      additionalStyles,
      inventoryTaken,
      firstLegTimeStamp = '',
      supportCars,
      supportPassengers,
      supportTrucks,
      supportBicycles,
      sailPackage,
      isLegBack,
    } = this.props
    const availableTickets = transformSailInventoriesToTickets(sailInventories)
    // const showRedWarning = checkOutOfStock(availableTickets)

    const [warning1, warning2] = warnings

    const totallyOutOfStock = availableTickets.filter((item) => {
      const { availabilityTickets } = item
      let countOutOfStock = 0
      for (let i = 0; i < availabilityTickets.length; i += 1) {
        if (availabilityTickets[i].availability === 0) countOutOfStock += 1
      }
      return Boolean(countOutOfStock)
    })

    const showRedWarning = totallyOutOfStock.length > 0

    const voyages = timeSort(availableTickets).filter((ticket) => {
      if (firstLegTimeStamp && isMaritime) {
        return moment(firstLegTimeStamp).isBefore(moment(ticket.sail.departure.timestamp))
      }
      return true
    })

    let totalReserves = 0
    const showWarningsForLocals =
      voyages.length > 0
        ? voyages.reduce(
            (acc, voyage) =>
              voyage.availabilityTickets.reduce((innerAcc, ticket) => {
                if (ticket.type === 'Passenger') {
                  totalReserves += ticket.reserves.amount
                }
                return ticket.reserves.show ? !ticket.reserves.amount || innerAcc : ticket.reserves.show || innerAcc
              }, false) || acc,
            false
          )
        : false
    const showHintAboutLocals =
      voyages.length > 0
        ? voyages.find(
            (voyage) =>
              voyage &&
              voyage.availabilityTickets &&
              voyage.availabilityTickets.some((availability) => availability.reserves && availability.reserves.show)
          )
        : false

    const checkRoute = (route) => route.startsWith('RIN-')

    const twoHoursBeforeDeparture = availableTickets.filter((item) => item.sail.status === 'CLOSED').length > 0

    const showTextForLines1 =
      selectedRoute === 'SVI-ROH-SVI' ||
      selectedRoute === 'SOR-TRI-SOR' ||
      selectedRoute === 'KIH-MUN-KIH' ||
      selectedRoute === 'KIH-PAR-KIH' ||
      selectedRoute === 'MAN-MUN-MAN'

    const mergeAvailability = (acc, { availabilityTickets }) => {
      const addToAcc = ({ type, availability }) => {
        acc[type] = add(pathOr(0, [type], acc), availability)
      }
      forEach(addToAcc, availabilityTickets)
      return acc
    }

    const voyagesAvailability = reduce(mergeAvailability, {}, voyages)
    voyagesAvailability.totalReserves = totalReserves

    const isThereCancelledSails =
      voyages.filter((voyage) => pathOr('', ['sail', 'status'])(voyage) === 'CANCELLED').length > 0
    const hasOnlyCancelledSails =
      voyages.filter((voyage) => pathOr('', ['sail', 'status'])(voyage) === 'CANCELLED').length === voyages.length

    const isThereHovercraft =
      voyages.filter((voyage) => pathOr('', ['sail', 'vesselType'])(voyage) === 'AIR_CUSHION_VESSEL').length > 0
    const hasOnlyHovercraftSails =
      voyages.filter((voyage) => pathOr('', ['sail', 'vesselType'])(voyage) === 'AIR_CUSHION_VESSEL').length ===
      voyages.length
    const hasOnlySeatsAndBikes =
      voyages.filter(
        (voyage) =>
          pathOr('', ['sail', 'vesselFeatures', 'hasBicycleSpots'])(voyage) === true &&
          pathOr('', ['sail', 'vesselFeatures', 'hasSeats'])(voyage) === true &&
          pathOr('', ['sail', 'vesselFeatures', 'hasCarDecks'])(voyage) === false &&
          pathOr('', ['sail', 'vesselFeatures', 'hasCabins'])(voyage) === false
      ).length === voyages.length ||
      (voyages.find(
        (voyage) =>
          pathOr('', ['sail', 'vesselFeatures', 'hasBicycleSpots'])(voyage) === true &&
          pathOr('', ['sail', 'vesselFeatures', 'hasSeats'])(voyage) === true &&
          pathOr('', ['sail', 'vesselFeatures', 'hasCarDecks'])(voyage) === false &&
          pathOr('', ['sail', 'vesselFeatures', 'hasCabins'])(voyage) === false
      ) &&
        !voyages.find(
          (voyage) =>
            pathOr('', ['sail', 'vesselFeatures', 'hasBicycleSpots'])(voyage) === false &&
            pathOr('', ['sail', 'vesselFeatures', 'hasSeats'])(voyage) === true &&
            pathOr('', ['sail', 'vesselFeatures', 'hasCarDecks'])(voyage) === true &&
            pathOr('', ['sail', 'vesselFeatures', 'hasCabins'])(voyage) === false
        ) &&
        !voyages.find(
          (voyage) =>
            pathOr('', ['sail', 'vesselFeatures', 'hasBicycleSpots'])(voyage) === true &&
            pathOr('', ['sail', 'vesselFeatures', 'hasSeats'])(voyage) === true &&
            pathOr('', ['sail', 'vesselFeatures', 'hasCarDecks'])(voyage) === true &&
            pathOr('', ['sail', 'vesselFeatures', 'hasCabins'])(voyage) === false
        ))
    const hasOnlySeatsAndCars =
      voyages.filter(
        (voyage) =>
          pathOr('', ['sail', 'vesselFeatures', 'hasBicycleSpots'])(voyage) === false &&
          pathOr('', ['sail', 'vesselFeatures', 'hasSeats'])(voyage) === true &&
          pathOr('', ['sail', 'vesselFeatures', 'hasCarDecks'])(voyage) === true &&
          pathOr('', ['sail', 'vesselFeatures', 'hasCabins'])(voyage) === false
      ).length === voyages.length ||
      (voyages.find(
        (voyage) =>
          pathOr('', ['sail', 'vesselFeatures', 'hasBicycleSpots'])(voyage) === false &&
          pathOr('', ['sail', 'vesselFeatures', 'hasSeats'])(voyage) === true &&
          pathOr('', ['sail', 'vesselFeatures', 'hasCarDecks'])(voyage) === true &&
          pathOr('', ['sail', 'vesselFeatures', 'hasCabins'])(voyage) === false
      ) &&
        !voyages.find(
          (voyage) =>
            pathOr('', ['sail', 'vesselFeatures', 'hasBicycleSpots'])(voyage) === true &&
            pathOr('', ['sail', 'vesselFeatures', 'hasSeats'])(voyage) === true &&
            pathOr('', ['sail', 'vesselFeatures', 'hasCarDecks'])(voyage) === false &&
            pathOr('', ['sail', 'vesselFeatures', 'hasCabins'])(voyage) === false
        ) &&
        !voyages.find(
          (voyage) =>
            pathOr('', ['sail', 'vesselFeatures', 'hasBicycleSpots'])(voyage) === true &&
            pathOr('', ['sail', 'vesselFeatures', 'hasSeats'])(voyage) === true &&
            pathOr('', ['sail', 'vesselFeatures', 'hasCarDecks'])(voyage) === true &&
            pathOr('', ['sail', 'vesselFeatures', 'hasCabins'])(voyage) === false
        ))
    const hasOnlyPassengers =
      voyages.filter(
        (voyage) =>
          pathOr('', ['sail', 'vesselFeatures', 'hasBicycleSpots'])(voyage) === false &&
          pathOr('', ['sail', 'vesselFeatures', 'hasSeats'])(voyage) === true &&
          pathOr('', ['sail', 'vesselFeatures', 'hasCarDecks'])(voyage) === false &&
          pathOr('', ['sail', 'vesselFeatures', 'hasCabins'])(voyage) === false
      ).length === voyages.length

    const { onSelectTicket, onSelectTicketWhileEdit } = this.props
    const handleSelectSail = onEditMode ? onSelectTicketWhileEdit : onSelectTicket

    if (!ticketsIsFetching && isEmpty(voyages) && isMaritime) {
      return (
        <UILayout padding="0 10px" center column sm={{ padding: '20px 0 0 0' }}>
          <UIText
            font="ADPortsGroupBold, sans-serif"
            color="#9DA7BF"
            size="22px"
            translate={messages.noMoreSailsTitle}
          />
          <UIText maxWidth="250px" color="#9DA7BF" size="16px" translate={messages.noMoreSailsSubtitle} />
        </UILayout>
      )
    }

    return (
      <UILayout column display-if={voyages}>
        {ticketsIsFetching && !isMaritime ? (
          <UILayout column width="auto" align="center" padding="20px">
            <CircularProgress />
          </UILayout>
        ) : (
          <React.Fragment>
            <UILayout
              j-flex-center
              display-if={isMaritime && notEmpty(voyages) && !isLegBack && sailPackage.route === 'ALS-ALA-ALS'}
              sm={{ padding: '20px 0 0 0' }}
            >
              <UIText size="16px" color="#2D3955" translate={messages.privateServiceTitle} />
            </UILayout>
            <UILayout
              display-if={isMaritime && notEmpty(voyages) && !isBluewave}
              j-flex-center={!hasOnlyPassengers}
              padding={hasOnlyPassengers ? '0 20px 50px 210px' : '0px 20px 50px 20px'}
              sm={{ padding: hasOnlyPassengers ? '20px 0 50px 70px' : '20px 0 50px 0' }}
            >
              <UIText padding="0 0 0 10%" size="14px" color="#737F9A" translate={messages.availableCapacity} />
            </UILayout>

            <UILayout
              data-testid="sail-select-table"
              column
              margin="-40px 0 0 0"
              style={{ paddingInlineStart: getBluewaveStyle('0', '20px') }}
              sm={xsStyles.padding0MarginTop20}
              {...additionalStyles}
              className={moduleStyles.formDirection}
            >
              {/* Ticket items header */}
              <UILayout display-if={head(voyages)} row padding={getGavdosStyle('0 0 40px 0', '0 0 10px 0')}>
                <TicketRowHeader
                  availability={voyagesAvailability}
                  hasOnlySeatsAndBikes={hasOnlySeatsAndBikes}
                  hasOnlyPassengers={hasOnlyHovercraftSails || hasOnlyPassengers}
                  hasOnlySeatsAndCars={hasOnlySeatsAndCars}
                  supportCars={supportCars}
                  supportPassengers={supportPassengers}
                  supportTrucks={supportTrucks}
                  supportBicycles={supportBicycles}
                />
              </UILayout>
              <Voyages
                voyages={voyages}
                selectedSail={selectedSail}
                onSelect={handleSelectSail}
                onEditMode={onEditMode}
                setEditedReservationData={this.props.setEditedReservationData}
                route={selectedRoute}
                inventoryTaken={inventoryTaken}
                hasOnlyPassengers={hasOnlyHovercraftSails || hasOnlyPassengers}
                hasOnlySeatsAndCars={hasOnlySeatsAndCars}
                locale={intl.locale}
                supportCars={supportCars}
                supportPassengers={supportPassengers}
                supportTrucks={supportTrucks}
                supportBicycles={supportBicycles}
              />
              <UILayout
                display-if={isMaritime && getShouldShowNextDayDeparturesMessage(voyages)}
                padding="10px 0 20px 0"
              >
                <UIText
                  font="ADPortsGroupBold, sans-serif"
                  size="16px"
                  color="#13C5BD"
                  translate={messages.nextDayDepartures}
                />
              </UILayout>
            </UILayout>
          </React.Fragment>
        )}

        {/* Note about tickets */}

        <UILayout
          display-if={
            (checkRoute(selectedRoute) || showHintAboutLocals) &&
            sailInventories.length > 0 &&
            voyages.length > 0 &&
            !hasOnlyCancelledSails
          }
          j-flex-start
          maxWidth="100%"
          padding="7px 36px 0 24px"
          sm={xsStyles.marginVertical10}
          column
          style={styles.warning}
        >
          <UIText
            color="#94a8b2"
            align="left"
            textTransform="uppercase"
            weight="bold"
            font={muiTheme.secondFontFamilyDem}
            size="14px"
            translate={messages.noteAboutTicketPart4}
          />
        </UILayout>

        <UILayout
          data-test="departure-warnings"
          display-if={twoHoursBeforeDeparture}
          j-flex-start
          padding="7px 36px 0 24px"
          sm={xsStyles.marginVertical10}
          column
          style={styles.warning}
        >
          <UIText
            display-if={false}
            color="#d0021b"
            align="left"
            textTransform="uppercase"
            weight="bold"
            font={muiTheme.secondFontFamilyDem}
            size="14px"
            translate={messages.warningText1Part1}
            margin="0 0 5px 0"
          />
        </UILayout>

        <UILayout
          display-if={!isThereCancelledSails && showRedWarning && (warning1 || warning2)}
          j-flex-start
          maxWidth="100%"
          padding="7px 36px 0 24px"
          sm={xsStyles.marginVertical10}
          column
          style={styles.warning}
        >
          <UIText
            display-if={warning1}
            color="#f46e5a"
            align="left"
            textTransform="uppercase"
            weight="bold"
            font={muiTheme.secondFontFamilyDem}
            size="14px"
          >
            {<span style={{ ...styles.mediumStar, ...styles.bigStar }}>{capacityWarningNote}</span>}
            {warning1}
          </UIText>

          <UIText
            display-if={showTextForLines1 && warning2}
            color="#f46e5a"
            align="left"
            textTransform="uppercase"
            weight="bold"
            font={muiTheme.secondFontFamilyDem}
            size="14px"
            margin="10px 0 0 0"
          >
            {warning2}
          </UIText>
        </UILayout>

        <UILayout
          display-if={showWarningsForLocals && getLocalsSoldOutAttribute(intl.locale.toUpperCase())}
          j-flex-start
          maxWidth="100%"
          padding="7px 36px 0 24px"
          sm={xsStyles.marginVertical10}
          column
          style={styles.warning}
        >
          <UIText
            color="#f46e5a"
            align="left"
            textTransform="uppercase"
            weight="bold"
            font={muiTheme.secondFontFamilyDem}
            size="14px"
          >
            {<span style={styles.mediumStar}>{noMoreLocalsTicketsNote}</span>}
            {getLocalsSoldOutAttribute(intl.locale.toUpperCase())
              ? getLocalsSoldOutAttribute(intl.locale.toUpperCase()).value
              : ''}
          </UIText>
        </UILayout>
        <UILayout
          className={moduleStyles.formDirection}
          display-if={isThereHovercraft}
          data-test="no-about-dangerous"
          marginTop="7px"
          center
          style={styles.warning}
        >
          <UIIcon type="iconHovercraft" width="25px" height="25px" margin="0 10px 0 0" />
          <UIText
            size={getStyle(onEditMode ? '18px' : '14px', '14px')}
            weight={'bold'}
            color={'#2E8DD9'}
            textTransform="uppercase"
            font={muiTheme.name === THEME.SAAREMAA ? muiTheme.thirdFontFamily : muiTheme.secondFontFamilyDem}
            translate={messages.noteAboutHovercraft}
          />
        </UILayout>
        <UILayout
          className={moduleStyles.formDirection}
          display-if={this.isDangerous()}
          data-test="no-about-dangerous"
          marginTop="7px"
          center
          style={styles.warning}
        >
          <UIIcon type="iconDanger" width="25px" height="25px" margin="0 10px 0 0" />
          <UIText
            size={getStyle(onEditMode ? '18px' : '14px', '14px')}
            color={
              muiTheme.palette && muiTheme.palette.warningSecondary ? muiTheme.palette.warningSecondary : '#ff5a19'
            }
            weight={'bold'}
            textTransform="uppercase"
            font={muiTheme.name === THEME.SAAREMAA ? muiTheme.thirdFontFamily : muiTheme.secondFontFamilyDem}
            translate={messages.noteAboutDangerous}
          />
        </UILayout>

        <UILayout
          display-if={
            this.isRestrictedPrice() && muiTheme.name !== THEME.SAAREMAA && !hasOnlySeatsAndBikes && !hasOnlyPassengers
          }
          className={moduleStyles.formDirection}
          data-test="no-about-dangerous"
          marginTop="7px"
          center
          style={styles.warning}
        >
          <UIIcon
            className={cn(moduleStyles.rtlIcon, moduleStyles.endMargin10px)}
            type="iconNoHeavyTrucks"
            width="25px"
            height="25px"
          />
          <UIText
            size={getStyle(onEditMode ? '18px' : '14px', '14px')}
            color="#000"
            textTransform="uppercase"
            font={muiTheme.secondFontFamilyDem}
            translate={messages.noteNoHeavyTrucks}
          />
        </UILayout>

        <UILayout
          className={moduleStyles.formDirection}
          display-if={isThereCancelledSails}
          data-test="no-about-dangerous"
          marginTop="7px"
          center
          style={styles.warning}
        >
          <UIIcon type="iconCancelledSail" width="25px" height="25px" margin="0 10px 0 0" />
          <UIText
            size={getStyle(onEditMode ? '18px' : '14px', '14px')}
            weight={'bold'}
            color={muiTheme.palette && muiTheme.palette.errorSecondary ? muiTheme.palette.errorSecondary : '#b42a2a'}
            textTransform="uppercase"
            font={muiTheme.name === THEME.SAAREMAA ? muiTheme.thirdFontFamily : muiTheme.secondFontFamilyDem}
            translate={messages.noteAboutCancelled}
          />
        </UILayout>
      </UILayout>
    )
  }

  isDangerous() {
    const { sailInventories } = this.props
    return sailInventories.some(
      (inventory) =>
        inventory.sail.dangerousGoods &&
        inventory.inventory &&
        inventory.inventory.inventories &&
        inventory.inventory.inventories.enabled
    )
  }

  isRestrictedPrice() {
    const { sailInventories } = this.props
    return sailInventories.some(
      (inventory) =>
        inventory.sail.restrictedPrices &&
        inventory.inventory &&
        inventory.inventory.inventories &&
        inventory.inventory.inventories.enabled
    )
  }
}

AvailableTickets.contextTypes = {
  muiTheme: PropTypes.object,
  intl: PropTypes.object,
}

AvailableTickets.propTypes = {
  ticketsIsFetching: PropTypes.bool,
  sailInventories: PropTypes.array,
  selectedSail: PropTypes.object,
  onSelectTicket: PropTypes.func,
  onSelectTicketWhileEdit: PropTypes.func,
  isDangerous: PropTypes.bool,
  isRestrictedPrice: PropTypes.bool,
  onEditMode: PropTypes.bool,
  isLegBack: PropTypes.bool,
  firstLegTimeStamp: PropTypes.string,
  additionalStyles: PropTypes.object,
  selectedRoute: PropTypes.any.isRequired,
  warnings: PropTypes.arrayOf(PropTypes.string).isRequired,
}

AvailableTickets.defaultProps = {
  sailInventories: [],
  selectedSail: {},
  onSelectTicket: () => {},
  onSelectTicketWhileEdit: () => {},
  ticketsIsFetching: false,
  onEditMode: false,
  additionalStyles: {},
  isLegBack: false,
  firstLegTimeStamp: '',
}

const makeMapStateToProps = () => {
  const getSelectedSailPackageCode = selectors.getSelectedSailPackageCodeFromProps()

  const mapStateToProps = (state, props) => {
    const selectedSailPackageCode = getSelectedSailPackageCode(state, props)
    const { sailPackage } = props
    const timeStamps = selectors.selectScheduleState(state).timeStamps || {}
    let firstLegTimeStamp = ''
    if (props.isLegBack && timeStamps && isMaritime) {
      // 3jm83wwq: turns of here if needed
      const anotherCode = Object.keys(selectors.selectScheduleState(state).availableDates).find(
        (code) => code !== sailPackage.code
      )
      if (anotherCode) firstLegTimeStamp = timeStamps[anotherCode] || ''
    }
    const availableDates = selectors.selectScheduleState(state).availableDates[selectedSailPackageCode]
    const sailInventories = selectors.sailInventoriesBySailPackageCode(selectedSailPackageCode)(state)
    const selectedSail = props.onEditMode
      ? selectedSailBySailPackageCodeWhileEdit(state)(selectedSailPackageCode, props.isCancelled)
      : selectors.selectedSailBySailPackageCode(state)(selectedSailPackageCode)
    const getLocalsSoldOutAttribute = (locale) =>
      map(
        (item) => ({
          code: item.attribute.code,
          value: pathOr('', ['structure', 'value', 'value'])(item) || pathOr('', ['structure', 'values'])(item),
        }),
        pathOr([], ['routes', `${getSelectedSailPackageFirstRoute(state)}`, 'attributes'])(state)
      ).find((item) => item.code === `ONLINE_SOLD_OUT_INFO_RESIDENT_${locale}`)

    const selectedRouteCode = getSelectedRoute(state)
    const supportCars = getAttributeFromRoute(selectedRouteCode, 'SUPPORT_CARS')(state)
    const supportPassengers = getAttributeFromRoute(selectedRouteCode, 'SUPPORT_PASSENGERS')(state)
    const supportTrucks = getAttributeFromRoute(selectedRouteCode, 'SUPPORT_TRUCKS')(state)
    const supportBicycles = getAttributeFromRoute(selectedRouteCode, 'SUPPORT_BICYCLES')(state)

    return {
      ticketsIsFetching: selectors.getTicketsIsFetching(state),
      selectedSail,
      sailInventories,
      showAvailableTickets: availableDates ? availableDates[selectors.getSailDate(state)] : false,
      selectedRoute: pathOr('', ['route'], sailPackage),
      warnings: getWarnings(sailInventories || []),
      getLocalsSoldOutAttribute,
      firstLegTimeStamp,
      supportCars,
      supportPassengers,
      supportTrucks,
      supportBicycles,
    }
  }
  return mapStateToProps
}

export default connect(makeMapStateToProps, {
  onSelectTicket: actions.setSelectedSail,
  onSelectTicketWhileEdit: setSelectedSailForEdit,
  setEditedReservationData,
})(AvailableTickets)
