import { UIIcon, UILayout, UIText } from 'bora-material-ui'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import messages from '../../../consts/messages'
import { isMaritime } from '../../../utils/maritimeStyleUtils'
import { isMobile } from '../../Common/components/AppBar'
import { actions } from '../provider'
import LoginMaritime from './LoginMaritime'
import RecoveryMaritime from './RecoveryMaritime'
import RegisterMaritime from './RegisterMaritime'
import moduleStyles from '../../Modal/Modals.module.scss'
import authStyles from './Login.module.scss'
import PropTypes from 'prop-types'
import { pathOr } from 'ramda'

export const LOGIN = 'login'
export const REGISTER = 'register'
export const RECOVERY = 'recovery'

const style = {
  button: {
    minHeight: '100px',
    center: true,
    cursor: 'pointer',
    style: {
      'padding-inline-start': '1rem',
    },
  },
  iconWithTextAfter: {
    marginInlineEnd: '1rem',
  },
}

class Auth extends React.Component {
  static contextTypes = {
    intl: PropTypes.object,
  }

  render() {
    if (!isMaritime) {
      return <Redirect to="/" />
    }

    const { intl } = this.context
    const locale = pathOr('en', ['locale'])(intl)
    const isArabic = locale === 'ar'

    let currentViewComponent = null

    switch (this.props.view) {
      case LOGIN:
        currentViewComponent = <LoginMaritime />
        break
      case REGISTER:
        currentViewComponent = <RegisterMaritime />
        break
      case RECOVERY:
        currentViewComponent = <RecoveryMaritime />
        break
      default:
        currentViewComponent = null
    }

    const isLogin = this.props.view === LOGIN
    const isRegister = this.props.view === REGISTER
    const isRecovery = this.props.view === RECOVERY

    return (
      <UILayout
        style={{ marginBlockStart: 60 }}
        className={moduleStyles.formDirection}
        maxWidth="760px"
        bgColor="white"
        data-testid="auth-container"
      >
        <UILayout
          column
          minWidth="225px"
          maxWidth="225px"
          bgColor="#F2F2F2"
          xs={{ minWidth: '70px', maxWidth: '70px' }}
        >
          <UILayout
            {...style.button}
            borderLeft={isRegister ? '6px solid #13C5BD' : '6px solid #2D3955'}
            onClick={() => this.props.goToPage('/auth/signup')}
          >
            <UIIcon
              className={moduleStyles.iconWithTextAfter}
              type={`iconRegister${isRegister ? 'Selected' : ''}_admaritime`}
            />
            <UIText color={isRegister ? '#13C5BD' : '#2D3955'} translate={messages.signUp} xs={{ display: 'none' }} />
          </UILayout>

          <UILayout
            {...style.button}
            borderLeft={isLogin ? '6px solid #13C5BD' : '6px solid #2D3955'}
            onClick={() => this.props.goToPage('/auth/login')}
          >
            <UIIcon
              className={moduleStyles.iconWithTextAfter}
              margin={isArabic ? '0 5px 0 0' : '0'}
              type={`iconLock${isLogin ? 'Selected' : ''}_admaritime`}
            />
            <UIText color={isLogin ? '#13C5BD' : '#2D3955'} translate={messages.signIn} xs={{ display: 'none' }} />
          </UILayout>

          <UILayout
            {...style.button}
            borderLeft={isRecovery ? '6px solid #13C5BD' : '6px solid #2D3955'}
            className={authStyles.recovery}
            onClick={() => this.props.goToPage('/auth/recovery')}
          >
            <UIText
              color={isRecovery ? '#13C5BD' : '#2D3955'}
              translate={isMobile ? messages.signForgotPassXs : messages.signForgotPass}
              xs={{ margin: isArabic ? '0 10px 0 0' : '0 0 0 5px' }}
            />
          </UILayout>

          <UILayout height="100%" borderLeft="6px solid #2D3955" />
        </UILayout>

        <UILayout column>{currentViewComponent}</UILayout>
      </UILayout>
    )
  }
}

export default connect(null, actions)(Auth)
