import { UILayout, UIText } from 'bora-material-ui'
import React from 'react'
import TicketRowInfoItemContainer from './TicketRowInfoItemContainer'

const style = getComputedStyle(document.body)
const fsBaseStrong = style.getPropertyValue('--fs-base-strong')
const fsExtraSmall = style.getPropertyValue('--fs-extra-small')
const colorPrimaryNormalLight = style.getPropertyValue('--color-primary-normal-light')

class TicketRowInfo extends React.Component {
  render() {
    const { isHeader = false, useInitialValues = false, items, withAdditionalTail = false } = this.props

    const renderHeaderLabels = (headerLabel, index) => {
      const isFirst = index === 0
      return (
        <TicketRowInfoItemContainer key={headerLabel} isFirst={isFirst} withAdditionalTail={withAdditionalTail}>
          <UIText
            text={headerLabel}
            color={colorPrimaryNormalLight}
            size={fsExtraSmall}
            align={isFirst ? 'start' : 'center'}
            xs={{ size: '12px' }}
          />
        </TicketRowInfoItemContainer>
      )
    }

    const renderRowLabelsWithInitialValues = (item, index) => {
      const isFirst = index === 0
      const { label, AfterLabelComponent } = item
      return (
        <TicketRowInfoItemContainer key={label} isFirst={isFirst} withAdditionalTail={withAdditionalTail}>
          <UIText
            text={label}
            color="black"
            size={fsBaseStrong}
            align={isFirst ? 'start' : 'center'}
            xs={{ size: '10px' }}
          />
          {AfterLabelComponent && <AfterLabelComponent />}
        </TicketRowInfoItemContainer>
      )
    }

    const renderRowLabels = (item, index) => {
      const isFirst = index === 0
      return (
        <TicketRowInfoItemContainer key={index} isFirst={isFirst} withAdditionalTail={withAdditionalTail}>
          <UIText
            text={item}
            color="black"
            size={fsBaseStrong}
            align={isFirst ? 'start' : 'center'}
            xs={{ size: '10px' }}
          />
        </TicketRowInfoItemContainer>
      )
    }

    const renderItems = () => {
      if (isHeader) return items.map(renderHeaderLabels)
      if (useInitialValues) return items.map(renderRowLabelsWithInitialValues)
      return items.map(renderRowLabels)
    }

    return <UILayout>{renderItems()}</UILayout>
  }
}

export default TicketRowInfo
