import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { pathOr } from 'ramda'
import { UILayout } from 'bora-material-ui'

import { VehicleDivider } from './components'
import { SimplifiedForm, InputManually, INAPPROPRIATE_UI_FORM, SCROLL_TO_SIMPLIFIED_FORM_DELAY } from './tabs'

import messages from '../../../../consts/messages'
import { VEHICLE_INFO_NOT_FOUND_IN_REGISTRY } from './tabs/consts'
import { VehicleFormTypeTabs } from './VehicleFormTypeTabs'
import { THEME } from 'bora-material-ui/themes'
import { isLiinilaevad } from '../../../../utils/liinilaevadThemeUtils'
import { getMaritimeStyle, isBluewave, isMaritime } from '../../../../utils/maritimeStyleUtils'
import { connect } from 'react-redux'
import { getSelectedTicketsValues } from '../../../../services/pricing/reducers/selectors'

const SIMPLE_TYPE = 'simple'
const ADVANCED_TYPE = 'input'

class VehiclesForm extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      scrollTriggered: false,
      scrolledElement: null,
      isEditingWeightShown: false,
      isAddButtonIsWaitingForResponse: false,
      isPluralWeightToBeReset: false,
      isManualVehicleFormShownExplicitly: false,
      addVehicleButtonIsAlreadyClicked: false,
      isLoading: false,
    }
  }

  componentDidMount() {
    if (isMaritime || isBluewave) {
      // this.setState({ isManualVehicleFormShownExplicitly: true })
      this.props.setInputType(ADVANCED_TYPE)
      this.setAddVehicleButtonIsAlreadyClicked(false)
    }
  }

  componentWillUnmount() {
    this.props.changeReduxFormField('plateNumberVehicle', {})
    this.props.changeReduxFormField('plateNumber', null)
  }

  componentDidUpdate(prevProps) {
    const { isVehicleActive, isVehicleAdded, scrollToDoneButton } = this.props
    const { scrollTriggered, scrolledElement } = this.state

    if (
      isVehicleActive &&
      isVehicleAdded === false &&
      scrollTriggered === false &&
      scrolledElement &&
      scrolledElement.scrollIntoView
    ) {
      setTimeout(() => {
        scrolledElement.scrollIntoView({ block: 'start', behavior: 'smooth' })
      }, SCROLL_TO_SIMPLIFIED_FORM_DELAY)

      this.setState({ scrollTriggered: true })
    }
    if (prevProps.selectedTickets.length < this.props.selectedTickets.length && isMaritime) {
      this.setIsLoading(false)
      scrollToDoneButton()
    }
  }

  switchToManually = () => {
    if (this.tabs.current) {
      this.tabs.current.handleChange(1)
    }
  }

  setAddVehicleButtonIsAlreadyClicked = (value) => {
    this.setState({ addVehicleButtonIsAlreadyClicked: value })
  }

  setIsLoading = (value) => {
    this.setState({ isLoading: value })
  }

  handleShowEditWeight = () => {
    this.setState((state) => ({ isEditingWeightShown: !state.isEditingWeightShown }))
  }

  handleAddButtonDisabled = () => {
    this.setState((state) => ({ isAddButtonIsWaitingForResponse: !state.isAddButtonIsWaitingForResponse }))
    this.props.handleVehicleAddingProcess(this.props.formName)
  }

  handlePluralWeightReset = () => {
    this.setState((state) => ({ isPluralWeightToBeReset: !state.isPluralWeightToBeReset }))
  }

  handleShowCompanyInputForManual = () => {
    this.setState({ isManualVehicleFormShownExplicitly: true })
  }

  render() {
    const { intl = {} } = this.context
    const {
      formName,
      iconType,
      changeReduxFormField,
      changeItemQtty,
      editMode,
      plateNumberVehicle,
      isManuallyTicket,
      inputType,
      setInputType,
      initialValues,
      changeIsActive,
      errorCode,
    } = this.props
    const {
      isEditingWeightShown,
      isAddButtonIsWaitingForResponse,
      isPluralWeightToBeReset,
      isManualVehicleFormShownExplicitly,
    } = this.state

    const { vehicleHasRegistryCategory = false, plateNumber = '' } = initialValues || {}

    const isSearchFailed = pathOr(null, ['status'])(plateNumberVehicle) === 'failed'
    const isFormInappropriate = pathOr(null, ['code'])(plateNumberVehicle) === INAPPROPRIATE_UI_FORM
    const plateNumberNotFound = pathOr(null, ['code'])(plateNumberVehicle) === VEHICLE_INFO_NOT_FOUND_IN_REGISTRY

    const className = 'trailers-switcher__button'
    const classNameActive = `${className} trailers-switcher__button_active`

    return (
      <Fragment>
        <span ref={(el) => this.setState({ scrolledElement: el })} />

        <UILayout padding={getMaritimeStyle('0', '15px 0 0')} width="100%" j-flex-center>
          <UILayout width="100%" column>
            {!(isMaritime || isBluewave) && (
              <React.Fragment>
                <VehicleDivider iconType={iconType} display-if={!editMode} />
                <VehicleFormTypeTabs
                  inputType={inputType}
                  SIMPLE_TYPE={SIMPLE_TYPE}
                  className={className}
                  classNameActive={classNameActive}
                  setInputType={setInputType}
                  isManuallyTicket={isManuallyTicket}
                  intl={intl}
                  changeIsActive={changeIsActive}
                  ADVANCED_TYPE={ADVANCED_TYPE}
                  isFormInappropriate={isFormInappropriate}
                  plateNumberNotFound={plateNumberNotFound}
                  isSearchFailed={isSearchFailed}
                />
              </React.Fragment>
            )}
            <UILayout
              display-if={
                (window.brandProps.theme === THEME.KIHNU ||
                  window.brandProps.theme === THEME.SAAREMAA ||
                  isLiinilaevad) &&
                inputType === SIMPLE_TYPE
              }
              label={intl.formatMessage(messages.siplifiedForm)}
            >
              <SimplifiedForm
                legitVehicleInfo={vehicleHasRegistryCategory && plateNumber}
                onChangeTab={this.switchToManually}
                changeField={changeReduxFormField}
                confirm={changeItemQtty}
                editMode={editMode}
                onGoToManuallyFormAction={() => setInputType(ADVANCED_TYPE)}
                formName={formName}
                handleShowEditWeight={this.handleShowEditWeight}
                isEditingWeightShown={isEditingWeightShown}
                handleAddButtonDisabled={this.handleAddButtonDisabled}
                isAddButtonIsWaitingForResponse={isAddButtonIsWaitingForResponse}
                isPluralWeightToBeReset={isPluralWeightToBeReset}
                handlePluralWeightReset={this.handlePluralWeightReset}
                handleInactiveRegisterCallback={this.handleShowCompanyInputForManual}
              />
            </UILayout>
            <UILayout label={intl.formatMessage(messages.inputManually)} display-if={inputType === ADVANCED_TYPE}>
              <InputManually
                changeField={changeReduxFormField}
                confirm={changeItemQtty}
                editMode={editMode}
                formName={formName}
                setAddVehicleButtonIsAlreadyClicked={this.setAddVehicleButtonIsAlreadyClicked}
                isLoading={this.state.isLoading}
                setIsLoading={this.setIsLoading}
                addVehicleButtonIsAlreadyClicked={this.state.addVehicleButtonIsAlreadyClicked}
                isManualVehicleFormShownExplicitly={isManualVehicleFormShownExplicitly}
                errorCode={errorCode}
              />
            </UILayout>
          </UILayout>
        </UILayout>
      </Fragment>
    )
  }
}

VehiclesForm.propTypes = {
  formName: PropTypes.string.isRequired,
  iconType: PropTypes.string.isRequired,
  isVehicleActive: PropTypes.bool,
  isVehicleAdded: PropTypes.bool,
  plateNumberVehicle: PropTypes.object,
  isManuallyTicket: PropTypes.bool,
  inputType: PropTypes.string.isRequired,
  changeReduxFormField: PropTypes.func.isRequired,
  changeIsActive: PropTypes.func.isRequired,
  changeItemQtty: PropTypes.func.isRequired,
  setInputType: PropTypes.func.isRequired,
  handleVehicleAddingProcess: PropTypes.func,
  editMode: PropTypes.bool,
}

VehiclesForm.defaultProps = {
  isVehicleActive: false,
  isVehicleAdded: false,
  plateNumberVehicle: {},
  isManuallyTicket: false,
  editMode: false,
}

VehiclesForm.contextTypes = {
  intl: PropTypes.any,
}

const mapStateToProps = (state) => {
  const ticketsFormValues = getSelectedTicketsValues(state)
  const selectedTickets = Object.keys(ticketsFormValues)
    .map((key) => ticketsFormValues[key])
    .filter((ticket) => typeof ticket === 'object' && ticket.code !== 'CHARTER')
  return {
    selectedTickets,
  }
}

export default connect(mapStateToProps, null)(VehiclesForm)
