import { UILayout } from 'bora-material-ui'
import React from 'react'

class TicketRowInfoItemContainer extends React.Component {
  render() {
    const { children, isFirst, withAdditionalTail = false } = this.props

    return (
      <UILayout
        width={isFirst && '100%'}
        minWidth={isFirst ? 'auto' : '70px'}
        j-flex-center={!isFirst}
        center
        margin={withAdditionalTail ? '0' : '0 0 10px 0'}
      >
        {children}
      </UILayout>
    )
  }
}

export default TicketRowInfoItemContainer
