/* eslint-disable max-lines-per-function */
import React from 'react'
import { connect } from 'react-redux'

import { UILayout, UIText } from 'bora-material-ui'

import messages from '../../../../consts/messages'
import { translate } from '../../../Common/Translator'
import { blur, change, Field } from 'redux-form'
import PropTypes from 'prop-types'
import { DropDownField, InputField, OptionsRadioGroup } from '../../../../formComponents'
import { includeOnlyCorrectSymbols, required as validateRequired } from '../../../../utils/validate'
import { birthDatesOptions, birthMonthsOptions, birthYearsOptions } from './Passengers-Date.util'
import { setReservationPassengerInfo } from '../../../../actions'
import { getCitizenshipList } from '../../../../services/user/selectors'
import { take } from 'ramda'
import { getMaritimeStyle, isMaritime } from '../../../../utils/maritimeStyleUtils'
import { fillBookingPassengerDataForm, isReallyProduction } from '../../../../utils/formFillinUtils'
import { fillButtonStyles } from './CustomerInfo'
import moduleStyles from '../../../Modal/Modals.module.scss'
import { getLocale } from '../../../../services/user-selections'
import { isMobile } from '../../../Common/components/AppBar'
import UIElements from '../../../../consts/UIElements'
import { getToken } from '../../../../services/auth/reducers/selectors'
import SaveDataCheckbox from './SaveDataCheckbox'

const birthDayWrapStyleXs = { style: isMaritime ? {} : { flexDirection: 'column', alignItems: 'stretch', padding: 0 } }
const dateFldStyle = isMaritime
  ? { width: '33%', padding: '0 5px 0 0' }
  : { minWidth: '100px', margin: '0 10px 0 0', padding: 0, width: '100%' }
const dateFldStyleXs = { minWidth: '75px', maxWidth: '80px', height: '35px' }
const yearFldStyle = isMaritime ? { width: '33%', padding: '0 5px 0 0' } : { ...dateFldStyle, margin: 0 }
const yearFldStyleXs = { minWidth: getMaritimeStyle('90px', '120px') }
const monthFldStyle = isMaritime ? { width: '33%', padding: '0 5px 0 0' } : { ...dateFldStyle, minWidth: '150px' }

const labelStyle = { display: 'none' }
const inputContainerStyle = { padding: 0 }
const inputInnerStyle = isMaritime
  ? { boxShadow: 'none', border: '1px solid #D4D4D4', borderRadius: '4px' }
  : { boxShadow: 'none', borderBottom: '1px solid rgb(191, 195, 199)' }

const commentsLabelStyles = {
  width: getMaritimeStyle(isMobile ? '120px !important' : '257px', '120px'),
  size: getMaritimeStyle('16px', '17px'),
  fontWeight: 'normal',
  color: getMaritimeStyle('#000000', '#79909b'),
  weight: 'normal',
  height: getMaritimeStyle('76%', 'auto'),
}

const checkboxStyles = {
  label: {
    size: '22px',
    color: 'black',
    width: 'auto',
    margin: '1px 30px',
    ...(window.brandProps.muiTheme.ids.Confirmation &&
      window.brandProps.muiTheme.ids.Confirmation.rowText && {
        'font-family': window.brandProps.muiTheme.ids.Confirmation.rowText.font,
      }),
  },
  labelIcon: { width: 'auto', marginInlineEnd: '0.5rem', marginInlineStart: '0', fill: UIElements.FONT_COLOR },
}

export const GENDER_BUTTONS = [
  { title: 'Male', translation: messages.male, value: 'M' },
  { title: 'Female', translation: messages.female, value: 'F' },
]

const Buttons = (props) => {
  const {
    input: { onChange },
    index,
    formFields,
  } = props

  const currentSelection = formFields.get(index).gender

  if (isMaritime) {
    return (
      <OptionsRadioGroup
        prohibitTextTransform
        hideLabel
        row
        name="genderRadio"
        options={GENDER_BUTTONS}
        activeValue={currentSelection}
        onChange={() => {
          if (!currentSelection || currentSelection === 'F') {
            onChange('M')
            return
          }
          if (!currentSelection || currentSelection === 'M') {
            onChange('F')
          }
        }}
      />
    )
  }

  return (
    <UILayout
      className="trailers-switcher"
      borderRadius="15px"
      height={getMaritimeStyle('30px', 'auto')}
      margin={getMaritimeStyle('18px 0 0 0', '16px 0 0 0')}
    >
      <button
        className={
          !currentSelection || currentSelection === 'F'
            ? 'trailers-switcher__button'
            : 'trailers-switcher__button trailers-switcher__button_active'
        }
        onClick={() => {
          if (!currentSelection || currentSelection === 'F') {
            onChange('M')
          }
          return true
        }}
      >
        {translate(messages.male)[0]}
      </button>
      <button
        className={
          !currentSelection || currentSelection === 'M'
            ? 'trailers-switcher__button'
            : 'trailers-switcher__button trailers-switcher__button_active'
        }
        onClick={() => {
          if (!currentSelection || currentSelection === 'M') {
            onChange('F')
          }
          return true
        }}
      >
        {translate(messages.female)[0]}
      </button>
    </UILayout>
  )
}

const ButtonFields = ({ name, label, validate = [], required, placeholder, ...props }) => {
  const fieldProps = { required }
  const validateFunc = required ? [validateRequired, ...validate] : validate

  return (
    <Field
      name={name}
      type="text"
      label={label}
      component={Buttons}
      props={fieldProps}
      placeholder={placeholder}
      validate={validateFunc}
      {...props}
    />
  )
}

const CompanyItemTemplate = (props) => {
  if (!props) return null
  return (
    <UILayout height="44px" align="center">
      <UIText size="15px" color="#000000" text={props.title || props.value.title} />
    </UILayout>
  )
}

const NOT_A_COUNTRY_CODE = 'XXX'

export const CountryItemTemplate = (props) => {
  if (!props) return null
  const { code, title } = props
  return (
    <UILayout height="44px" align="center">
      <UIText size="15px" color="#000000" text={code === NOT_A_COUNTRY_CODE ? '' : title} />
    </UILayout>
  )
}

const alignItemsFlexEnd = {
  alignItems: 'flex-end',
}

class PassengerEditForm extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.fillPassenger = this.fillPassenger.bind(this)
  }

  state = {
    list: [],
  }

  componentDidMount() {
    this.setInitialList()
  }

  fillPassenger() {
    fillBookingPassengerDataForm(this.props.change, 'bookingForm', this.props.passenger)
  }

  setInitialList() {
    const { citizenships = [] } = this.props
    const citizenshipList = [...take(10)(citizenships), { code: NOT_A_COUNTRY_CODE, title: 'Load all' }]
    this.setState({
      list: citizenshipList,
    })
  }

  render() {
    const { muiTheme } = this.context
    const {
      FormFields = [],
      index,
      fields,
      passenger,
      citizenships = [],
      isDateValid,
      validationErrorMessage,
      saveDataCheckboxEnabled,
      seqN,
      locale,
      isLogged,
    } = this.props
    const citizenshipList = this.state.list
    const errorStyles = {
      'margin-top': '6px',
      'font-size': getMaritimeStyle('10px', '12px'),
      color: getMaritimeStyle('#D11800'),
      'font-family': muiTheme.secondFontFamily,
    }
    const dateErrorStyle = isMaritime && !isDateValid ? { border: '1px solid #D11800', background: '#FAE8E6' } : {}

    const additionalArabicStyles =
      locale === 'ar'
        ? {
            direction: 'rtl',
            textAlign: 'right',
          }
        : {}

    if (locale === 'ar') {
      commentsLabelStyles.align = 'right'
    } else {
      commentsLabelStyles.align = 'left'
    }

    const CitizenshipItemTemplate = ({ value }) => {
      const { code, title } = value
      const wrapperStyle = code === NOT_A_COUNTRY_CODE ? alignItemsFlexEnd : {}
      const onClick =
        code === NOT_A_COUNTRY_CODE
          ? (e) => {
              e.stopPropagation()
              e.nativeEvent.stopImmediatePropagation()
              this.setState({ list: citizenships })
            }
          : () => {}
      return (
        <UILayout height="44px" onClick={onClick} style={wrapperStyle}>
          <UIText size="15px" color="#000000" text={title} display-if={code !== NOT_A_COUNTRY_CODE} />
          <UIText
            size="14px"
            color="#000000"
            weight="bold"
            translate={messages.loadAllCitizenships}
            display-if={code === NOT_A_COUNTRY_CODE}
          />
        </UILayout>
      )
    }
    return (
      <React.Fragment>
        <button style={fillButtonStyles} display-if={!isReallyProduction()} onClick={this.fillPassenger}>
          Fill random
        </button>
        {fields.get(index).personalIdentificationNumber ? (
          <React.Fragment>
            <UILayout row center width="100%" xs={{ flexFlow: 'column' }}>
              <UIText padding="10px 0 0 0 " size="18px" color="#79909b" align="left" minWidth="112px">
                {translate(messages.citizenship)}
              </UIText>
              <UILayout row center width="100%" padding="10px 0 0 9px">
                <DropDownField
                  onChange={(e, value) => {
                    this.props.blur('bookingForm', `${passenger}.citizenship`, value)
                    this.setInitialList()
                  }}
                  name={`${passenger}.citizenship`}
                  required
                  hideStar
                  styles={{ minWidth: '120px', padding: 0, width: '100%' }}
                  labelContainerProps={{ display: 'none' }}
                  inputContainerProps={{ padding: 0 }}
                  inputStyles={inputInnerStyle}
                  itemsArr={citizenshipList}
                  fullItemsList={citizenships}
                  itemTemplate={CitizenshipItemTemplate}
                  selectionTemplate={CountryItemTemplate}
                  errorStyles={errorStyles}
                />
              </UILayout>
            </UILayout>
            <UILayout column padding="12px 0 14px 0">
              <InputField
                key={`${passenger}.optionalInfo.resident`}
                hideStar
                name={`${passenger}.optionalInfo`}
                text={translate(messages.optionalInfo)}
                wrapperInputStyles={{ padding: '0' }}
                wrapperStyles={{ padding: '0', column: false }}
                errorStyles={errorStyles}
                style={{ boxShadow: '0 ', borderBottom: '1px solid #bfc3c7' }}
                wrapperLabelStyles={{ width: 'auto', 'flex-end': true, display: 'flex' }}
                labelStyles={{
                  width: '120px',
                  fontSize: getMaritimeStyle('16px', '17px'),
                  fontWeight: 'normal',
                  color: getMaritimeStyle('#000000', '#79909b'),
                  weight: 'normal',
                }}
                {...muiTheme.ids.InputField}
              />
            </UILayout>
          </React.Fragment>
        ) : (
          FormFields.map((field) => {
            if (field === 'gender') {
              return (
                <UILayout padding="10px 0 0 0" className={moduleStyles.direction}>
                  <UIText
                    width={getMaritimeStyle('51%')}
                    padding="20px 0 10px 0 "
                    size={getMaritimeStyle('16px', '18px')}
                    color={getMaritimeStyle('#000000', '#79909b')}
                    align="start"
                    minWidth="112px"
                    className={moduleStyles.direction}
                  >
                    {translate(messages[field])}
                  </UIText>
                  <UILayout width={getMaritimeStyle('49%', '104px')}>
                    <ButtonFields
                      onChange={(e, value) => {
                        this.props.blur('bookingForm', `${passenger}.${field}`, value)
                      }}
                      index={index}
                      currentSelection={fields.get(index).gender}
                      formFields={fields}
                      name={`${passenger}.${field}`}
                    />
                  </UILayout>
                </UILayout>
              )
            }
            if (field === 'birthday') {
              return (
                <UILayout
                  xs={birthDayWrapStyleXs}
                  className={moduleStyles.direction}
                  center
                  width="100%"
                  padding="12px 0 14px 0"
                >
                  <UIText
                    width={getMaritimeStyle('100%')}
                    padding="10px 0 0 0 "
                    size={getMaritimeStyle('16px', '18px')}
                    color={getMaritimeStyle('#000000', '#79909b')}
                    align="left"
                    minWidth="112px"
                    className={moduleStyles.direction}
                  >
                    {translate(messages.dateOfBirth)}
                  </UIText>
                  <UILayout
                    data-test="date-selection-fields"
                    position="relative"
                    center
                    width="100%"
                    padding={locale === 'ar' ? '0' : '10px 0 0 9px'}
                    justify="space-around"
                    xs={{ center: getMaritimeStyle(false) }}
                    className={moduleStyles.direction}
                  >
                    <DropDownField
                      onChange={(e, value) => {
                        this.props.blur('bookingForm', `${passenger}.birthday.date`, birthDatesOptions[value].index)
                      }}
                      name={`${passenger}.birthday.date`}
                      locale={locale}
                      required
                      hideStar
                      placeholder={'Day'}
                      styles={dateFldStyle}
                      labelContainerProps={labelStyle}
                      inputContainerProps={inputContainerStyle}
                      inputStyles={{
                        ...inputInnerStyle,
                        ...dateErrorStyle,
                        ...(locale === 'ar' ? { padding: '0 8px 0 0' } : {}),
                      }}
                      itemsArr={birthDatesOptions}
                      itemTemplate={CompanyItemTemplate}
                      selectionTemplate={CompanyItemTemplate}
                      errorStyles={errorStyles}
                      xs={dateFldStyleXs}
                    />

                    <DropDownField
                      onChange={(e, value) => {
                        this.props.blur('bookingForm', `${passenger}.birthday.month`, birthMonthsOptions[value].index)
                      }}
                      name={`${passenger}.birthday.month`}
                      locale={locale}
                      required
                      hideStar
                      styles={monthFldStyle}
                      labelContainerProps={labelStyle}
                      inputContainerProps={inputContainerStyle}
                      inputStyles={{
                        ...inputInnerStyle,
                        ...dateErrorStyle,
                        ...(locale === 'ar' ? { padding: '0 8px 0 0' } : {}),
                      }}
                      itemsArr={birthMonthsOptions}
                      itemTemplate={CompanyItemTemplate}
                      selectionTemplate={CompanyItemTemplate}
                      errorStyles={errorStyles}
                      xs={dateFldStyleXs}
                    />
                    <DropDownField
                      label="Year"
                      onChange={(e, value) => {
                        this.props.blur('bookingForm', `${passenger}.birthday.year`, value)
                      }}
                      name={`${passenger}.birthday.year`}
                      locale={locale}
                      required
                      hideStar
                      styles={yearFldStyle}
                      labelContainerProps={labelStyle}
                      inputContainerProps={inputContainerStyle}
                      inputStyles={{
                        ...inputInnerStyle,
                        ...dateErrorStyle,
                        ...(locale === 'ar' ? { padding: '0 8px 0 0' } : {}),
                      }}
                      itemsArr={birthYearsOptions}
                      itemTemplate={CompanyItemTemplate}
                      selectionTemplate={CompanyItemTemplate}
                      errorStyles={errorStyles}
                      xs={yearFldStyleXs}
                    />
                    <UILayout
                      display-if={!isDateValid}
                      position="absolute"
                      {...(locale === 'ar' ? { left: '-8px', top: '53px' } : { top: '60px', left: '10px' })}
                    >
                      <UIText
                        size="9px"
                        align="start"
                        font="ADPortsGroupBold, sans-serif"
                        color="#D11800"
                        text={validationErrorMessage}
                      />
                    </UILayout>
                  </UILayout>
                </UILayout>
              )
            }
            if (field === 'citizenship') {
              return (
                <UILayout row center width="100%">
                  <UIText
                    width={getMaritimeStyle('100%')}
                    padding="10px 0 0 0 "
                    size={getMaritimeStyle('16px', '18px')}
                    color={getMaritimeStyle('#000000', '#79909b')}
                    align="left"
                    minWidth="112px"
                  >
                    {translate(messages[field])}
                  </UIText>
                  <UILayout row center width="100%" padding="10px 0 0 9px">
                    <DropDownField
                      onChange={(e, value) => {
                        this.props.blur('bookingForm', `${passenger}.citizenship`, value)
                        this.setInitialList()
                      }}
                      name={`${passenger}.${field}`}
                      required
                      hideStar
                      styles={{ minWidth: '120px', padding: 0, width: '100%' }}
                      labelContainerProps={{ display: 'none' }}
                      inputContainerProps={{ padding: 0 }}
                      inputStyles={inputInnerStyle}
                      itemsArr={citizenshipList}
                      fullItemsList={citizenships}
                      itemTemplate={CitizenshipItemTemplate}
                      selectionTemplate={CountryItemTemplate}
                      errorStyles={errorStyles}
                    />
                  </UILayout>
                </UILayout>
              )
            }
            if (field === 'shouldSaveData') {
              if (!isLogged) {
                return null
              }

              return (
                <UILayout data-testid="save-logged-in-data" padding="10px 0 0 0" className={moduleStyles.formDirection}>
                  <UIText
                    width={getMaritimeStyle('51%')}
                    padding="20px 0 10px 0 "
                    size={getMaritimeStyle('16px', '18px')}
                    color={getMaritimeStyle('#000000', '#79909b')}
                    align="start"
                    minWidth="112px"
                    className={moduleStyles.direction}
                  >
                    {translate(messages[field])}
                  </UIText>
                  <UILayout padding="20px 0 0 0" width={getMaritimeStyle('49%', '104px')}>
                    <SaveDataCheckbox
                      saveDataCheckboxEnabled={saveDataCheckboxEnabled}
                      checkboxStyles={checkboxStyles}
                      seqN={seqN}
                    />
                  </UILayout>
                </UILayout>
              )
            }
            return (
              <UILayout column padding="12px 0 14px 0">
                <InputField
                  key={`${passenger}.${field}`}
                  required={field !== 'optionalInfo'}
                  hideStar
                  name={`${passenger}.${field}`}
                  text={translate(messages[field])}
                  wrapperInputStyles={{ padding: '0' }}
                  wrapperStyles={{
                    padding: '0',
                    column: false,
                  }}
                  errorStyles={errorStyles}
                  style={inputInnerStyle}
                  wrapperLabelStyles={{ width: 'auto', 'flex-end': true, display: 'flex', ...additionalArabicStyles }}
                  labelStyles={commentsLabelStyles}
                  validate={[includeOnlyCorrectSymbols]}
                  {...muiTheme.ids.InputField}
                />
              </UILayout>
            )
          })
        )}
      </React.Fragment>
    )
  }
}

PassengerEditForm.contextTypes = {
  intl: PropTypes.any,
  muiTheme: PropTypes.any,
}

const mapStateToProps = (state) => ({
  citizenships: getCitizenshipList(state),
  locale: getLocale(state),
  isLogged: Boolean(getToken(state)),
  // bookingFormValues:
  //   state && state.form && state.form.bookingForm && state.form.bookingForm.values
  //     ? state.form.bookingForm.values
  //     : null,
})

const mapDispatchToProps = {
  blur,
  change,
  setReservationPassengerInfo: (index) => setReservationPassengerInfo({ index }),
}

export default connect(mapStateToProps, mapDispatchToProps)(PassengerEditForm)
