/* eslint-disable complexity */
import React from 'react'
import { compose, map, prop } from 'ramda'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { change, getFormSyncErrors, getFormValues, reduxForm, touch } from 'redux-form'
import axios from 'axios'

import { UIButton, UICheckbox, UIIcon, UILayout, UIText } from 'bora-material-ui'

import { CustomerAccountPayment } from '../CustomerAccountPayment/index'
import { PaymentMethods } from '../PaymentMethods/index'
import TotalPrice from '../TotalPrice'

import UIElements from '../../../../../../../consts/UIElements'
import messages from '../../../../../../../consts/messages'
import { actions, selectors } from '../../../../../provider'
import { Alert } from '../../../../../../../components'
import { THEME } from 'bora-material-ui/themes'
import { goToPage } from '../../../../../../../actions'
import { ListItem, Logo } from '../PaymentMethods/PaymentMethods'
import { getStyle, isLiinilaevad } from '../../../../../../../utils/liinilaevadThemeUtils'
import { PaymentTerms } from './PaymentTerms'
import {
  getBluewaveStyle,
  getMaritimeStyle,
  isBluewave,
  isMaritime,
} from '../../../../../../../utils/maritimeStyleUtils'
import { getLocale } from '../../../../../../../services/user-selections'
import { areContactFormValuesValid } from '../../../../../../../utils/reservationOwner'
import { contactFields, contactFieldsAdm } from '../../../../../components/ContactFormFields'
import moduleStyles from '../../ConfirmPay/ConfirmationInfo/BaseModule.module.scss'
import { getPaymentStatus } from '../../../../../../../services/payment/selectors'
import { isMobile } from '../../../../../../Common/components/AppBar'

export const DivHTMLParser = ({ node, parent = {} }) =>
  node.children.map((props) => {
    if (props.children) {
      return <DivHTMLParser node={props} parent={props} />
    }

    return parent.name === 'a' ? (
      <a
        target="new"
        align="left"
        href={parent.attribs.href}
        color="rgb(44, 70, 132)"
        style={{
          fontFamily: getStyle('SourceSansPro, Sans-Serif'),
          color: getStyle('#3387CC', 'rgb(44, 70, 132)'),
        }}
      >
        {props.data}
      </a>
    ) : (
      <UIText
        align="left"
        color="#000000"
        font={getStyle('SourseSansPro, Sans-Serif')}
        {...(window.brandProps.muiTheme.ids.Confirmation &&
          window.brandProps.muiTheme.ids.Confirmation.rowText && {
            ...window.brandProps.muiTheme.ids.Confirmation.rowText,
          })}
      >
        {props.data}
      </UIText>
    )
  })

export const xsStyles = {
  wrapper: { justify: 'around-space', column: true },
  subWrapper: { padding: '0', width: 'auto', maxWidth: '100%' },
  priceWrapper: { padding: '10px 0' },
  termsWrapper: { padding: '0' },
  checkBoxWrapper: { padding: '10px 10px 0 10px' },
  paymentTerms: { padding: '10px' },
  waysWrapper: { padding: '0', width: 'auto', center: true },
  customerPayment: { marginInlineStart: '16px', width: '100%' },
  button: { width: getMaritimeStyle('100%', 'auto'), margin: '15px 0 0 0' },
}

const style = getComputedStyle(document.body)
const fontBold = style.getPropertyValue('--font-bold')
const fontBaseStrong = style.getPropertyValue('--fs-base-strong')

export const styles = {
  label: {
    size: getStyle(fontBaseStrong, '22px'),
    fontFamily: getStyle('SourceSansPro, Sans-Serif'),
    color: getMaritimeStyle('#13C5BD', getStyle('#3387CC')),
    textTransform: getStyle('uppercase'),
    fontWeight: 'bold',
    ...(window.brandProps.muiTheme.ids.Confirmation &&
      window.brandProps.muiTheme.ids.Confirmation.rowText && {
        'font-family': window.brandProps.muiTheme.ids.Confirmation.rowText.font,
      }),
  },
  labelIcon: { marginInlineEnd: '0.5rem', marginInlineStart: '0', fill: getStyle('#B7B7B7', UIElements.FONT_COLOR) },
  termsChildren: { marginBlockEnd: '5px' },
}

function isCreditPaymentAvailable(paymentMethods) {
  return paymentMethods.some((method) => method.creditClient)
}

class Payment extends React.Component {
  static contextTypes = {
    intl: PropTypes.any,
    muiTheme: PropTypes.object,
  }

  static defaultProps = {
    customerRole: {},
    termsAccepted: false,
    editMode: false,
  }

  static propTypes = {
    amount: PropTypes.string.isRequired,
    sailPackageCode: PropTypes.string.isRequired,
    onPaymentClick: PropTypes.func.isRequired,
    changeReduxFormField: PropTypes.func.isRequired,
    termsAccepted: PropTypes.bool,
    paymentMethods: PropTypes.array.isRequired,
    totalPrice: PropTypes.array.isRequired,
    customerRole: PropTypes.object,
    editMode: PropTypes.bool,
    paymentStatus: PropTypes.string,
  }

  state = {
    paymentStarted: false,
  }

  constructor() {
    super()
    this.handlePaymentClickProxy = this.handlePaymentClickProxy.bind(this)
  }

  handleInvoiceClick(paymentType) {
    if (isBluewave) {
      this.setState({ paymentStarted: true })
    } else {
      this.setState({ paymentStarted: true }, () => {
        setTimeout(() => {
          this.setState({ paymentStarted: false })
        }, 3000)
      })
    }

    this.props.onPaymentClick(paymentType, false)
  }

  handlePaymentClickProxy(...args) {
    if (isMaritime && args[0] === 'APPLEPAY') {
      return this.props.onPaymentClick(...args)
    }

    if (isMaritime) {
      this.setState({ paymentStarted: true })
    } else {
      this.setState({ paymentStarted: true }, () => {
        setTimeout(() => {
          this.setState({ paymentStarted: false })
        }, 3000)
      })
    }

    return this.props.onPaymentClick(...args)
  }

  componentDidMount() {
    this.props.changeReduxFormField('payment', 'termsAccepted', false)
  }

  // eslint-disable-next-line complexity
  render() {
    const {
      amount,
      changeReduxFormField,
      customerRole: {
        registrationNumber,
        monthlyCreditLimitBalance: creditLimit,
        monthlyCreditLimitBalanceFormatted: formattedCreditLimit = '42',
        purchaseAllowed: isPurchaseAllowed,
      },
      editMode,
      isCreditClient,
      paymentMethods,
      sailPackageCode,
      termsAccepted: isTermsAccepted,
      totalPrice,
      reservationId,
      currentReservation,
      locale,
      contactFormValid,
      touchFormFields,
    } = this.props

    const { reservationOwner: { email = '' } = {} } = currentReservation || {}
    const { intl = false, muiTheme } = this.context

    const { vivaWallet } = window.brandProps
    const centPrice = totalPrice * 100

    const customerAccountPaymentAvailable =
      isCreditPaymentAvailable(paymentMethods) && isPurchaseAllowed && registrationNumber
    if (customerAccountPaymentAvailable)
      console.info(`Button for customer account payment is available, company reg num ${registrationNumber}`)

    const handlePaymentClick = () => {
      if (isTermsAccepted) {
        axios
          .post(`/api/payment/${reservationId}/vivaw`, {
            email,
            currency: 'EUR',
            amount: centPrice, // do not trust this amount
          })
          .then(({ redirectUrl }) => {
            if (redirectUrl) window.location.href = redirectUrl
          })
      }
    }

    const isReservationReady =
      currentReservation &&
      currentReservation.reservationId &&
      currentReservation.reservationOwner &&
      currentReservation.items &&
      currentReservation.items.length

    return (
      <UILayout
        column={window.brandProps.theme === THEME.SAAREMAA || isMaritime || isLiinilaevad}
        borderBottom={getMaritimeStyle('none', getStyle('none', 'solid 1px #bfc3c7'))}
        padding="0 0 14px 0"
        sms={xsStyles.wrapper}
      >
        <UILayout
          column
          sms={xsStyles.subWrapper}
          {...(muiTheme.ids.Confirmation &&
            muiTheme.ids.Confirmation.termsWrapper && {
              ...muiTheme.ids.Confirmation.termsWrapper,
            })}
        >
          <UILayout
            row
            padding="0px 15px 15px 25px"
            sms={xsStyles.priceWrapper}
            display-if={!editMode && window.brandProps.theme !== THEME.SAAREMAA && !isMaritime && !isLiinilaevad}
          >
            <TotalPrice />
          </UILayout>

          <Alert warning={!isTermsAccepted || !isLiinilaevad} success={isTermsAccepted && isLiinilaevad}>
            <UILayout
              className={moduleStyles.formDirection}
              sms={xsStyles.checkBoxWrapper}
              data-testid="payment-terms-wrapper"
            >
              <UICheckbox
                labelStyle={styles.label}
                onCheck={(event, value) => {
                  touchFormFields('contact', ...map(prop('name'))(isMaritime ? contactFieldsAdm : contactFields))
                  changeReduxFormField('payment', 'termsAccepted', value)
                }}
                label={intl.formatMessage(messages.termsQue)}
                iconStyle={styles.labelIcon}
              />
            </UILayout>
            <PaymentTerms muiTheme={muiTheme} locale={locale} sailPackageCode={sailPackageCode} />
          </Alert>
        </UILayout>

        <UILayout
          column
          padding={getStyle(
            '30px 0',
            window.brandProps.theme === THEME.SAAREMAA ? '0' : getMaritimeStyle('25px 0 0 0', '0px 0px 0px 25px')
          )}
          sms={xsStyles.waysWrapper}
          data-test="payment-ways-wrapper"
        >
          <CustomerAccountPayment
            disabled={!isTermsAccepted || this.state.paymentStarted}
            display-if={customerAccountPaymentAvailable}
            formattedCreditLimit={formattedCreditLimit}
            isCreditLimitEnough={creditLimit >= totalPrice}
            onPaymentClick={this.handlePaymentClickProxy}
          />

          <UILayout
            margin="0 0 20px 0"
            xs={{ margin: '30px 0 20px 0' }}
            j-flex-center
            display-if={!isTermsAccepted && isMaritime}
          >
            <UIText
              display-if={!isBluewave}
              size={fontBaseStrong}
              color="#D11800"
              textTransform="uppercase"
              font={fontBold}
              translate={messages.confirmPageWarning}
            />
          </UILayout>

          <PaymentMethods
            disabled={
              !isTermsAccepted || this.state.paymentStarted || (isMaritime && !(contactFormValid && isReservationReady))
            }
            isLoading={this.state.paymentStarted && isMaritime}
            display-if={Number(amount) > 0 && window.brandProps.theme !== THEME.GAVDOS && !isBluewave}
            isCreditClient={isCreditClient}
            onPaymentClick={this.handlePaymentClickProxy}
            paymentMethods={paymentMethods}
            locale={locale}
          />

          <UILayout
            display-if={Number(amount) > 0 && window.brandProps.theme === THEME.GAVDOS && vivaWallet}
            column
            data-test="viva-wallet-button"
          >
            <UIButton
              display-if={muiTheme.ids.useSinglePaymentButton}
              disabled={!isTermsAccepted}
              background={muiTheme.ids.buttonStyles && muiTheme.ids.buttonStyles.background}
              onClick={handlePaymentClick}
            >
              <UILayout row center j-flex-center height="100%" width="100%">
                <UIText center translate={messages.pay} size="24px" color="white" margin="0 10px" />
                <Logo backgroundImage={'/images/gavdos/viva-wallet-logo.png'} style={{ height: '26px' }} />
              </UILayout>
            </UIButton>
            <ListItem
              key={'vivaWallet'}
              data-test="payment-method-viva"
              display-if={!muiTheme.ids.useSinglePaymentButton}
              disabled={!isTermsAccepted}
              onClick={handlePaymentClick}
            >
              <Logo backgroundImage={'/images/gavdos/viva-wallet-logo.png'} />
            </ListItem>
          </UILayout>

          <UILayout
            className={moduleStyles.formDirection}
            display-if={Number(amount) === 0 || isBluewave}
            center={isMaritime}
            data-test="if-zero-price"
            column={isMobile && isBluewave}
            j-flex-space-between
          >
            <UIButton
              id="buttonStyle"
              click={() => this.handlePaymentClickProxy('SWEDBANK')}
              maxWidth="100%!important"
              sms={xsStyles.button}
              disabled={
                !isTermsAccepted ||
                this.state.paymentStarted ||
                (isMaritime && !(contactFormValid && isReservationReady))
              }
              {...muiTheme.ids.buttonActionStyles}
              {...(muiTheme.ids.buttonActionStyles && { ...muiTheme.ids.buttonActionStyles.nextButton })}
              borderRadius={
                muiTheme.ids.buttonActionStyles &&
                muiTheme.ids.buttonActionStyles.nextButton &&
                muiTheme.ids.buttonActionStyles.nextButton.borderRadius
                  ? muiTheme.ids.buttonActionStyles.nextButton.borderRadius
                  : 'inherit'
              }
            >
              <UILayout
                center
                className={moduleStyles.marginAround}
                lineHeight="30px"
                height="100%"
                width="auto"
                j-flex-space-between
                maxWidth="100%"
              >
                <UILayout
                  column
                  width={getMaritimeStyle('100%', 'auto')}
                  style={{ ...(muiTheme.ids.buttonActionStyles && { ...muiTheme.ids.buttonActionStyles.subtitles }) }}
                  className={moduleStyles.endMargin1rem}
                >
                  <UIText
                    id="buttonLabelTextStyle"
                    align="left"
                    size={getMaritimeStyle('14px', '18px')}
                    opacity="0.5"
                    translate={messages.continue}
                    margin={
                      muiTheme.ids.buttonActionStyles && muiTheme.ids.buttonActionStyles.subtitles
                        ? muiTheme.ids.buttonActionStyles.subtitles.margin
                        : '0'
                    }
                  />
                  <UIText
                    id="buttonTextStyle"
                    align="left"
                    translate={isMaritime ? messages.submitButton : messages.checkout}
                    size="24px"
                    font={fontBold}
                    xs={{ size: getBluewaveStyle('20px') }}
                    {...(muiTheme.ids.buttonActionStyles && { ...muiTheme.ids.buttonActionStyles.titles })}
                  />
                </UILayout>
                <UIIcon fill="white" type="selectTicketArrow" className={moduleStyles.rtlIcon} />
              </UILayout>
            </UIButton>

            {false && (
              <UIButton
                click={() => this.handleInvoiceClick('INVOICE')}
                maxWidth="100%!important"
                sms={xsStyles.button}
                disabled={
                  !isTermsAccepted ||
                  this.state.paymentStarted ||
                  (isMaritime && !(contactFormValid && isReservationReady))
                }
                {...muiTheme.ids.buttonActionStyles}
                {...(muiTheme.ids.buttonActionStyles && { ...muiTheme.ids.buttonActionStyles.nextButton })}
                borderRadius={
                  muiTheme.ids.buttonActionStyles &&
                  muiTheme.ids.buttonActionStyles.nextButton &&
                  muiTheme.ids.buttonActionStyles.nextButton.borderRadius
                    ? muiTheme.ids.buttonActionStyles.nextButton.borderRadius
                    : 'inherit'
                }
              >
                <UILayout
                  center
                  className={moduleStyles.marginAround}
                  lineHeight="30px"
                  height="100%"
                  width="auto"
                  j-flex-space-between
                  maxWidth="100%"
                >
                  <UILayout
                    column
                    width={getMaritimeStyle('100%', 'auto')}
                    style={{ ...(muiTheme.ids.buttonActionStyles && { ...muiTheme.ids.buttonActionStyles.subtitles }) }}
                    className={moduleStyles.endMargin1rem}
                  >
                    <UIText
                      id="buttonTextStyle"
                      align="left"
                      // translate={isMaritime ? messages.submitButton : messages.checkout}
                      size="16px"
                      font={fontBold}
                      xs={{ lineHeight: 'initial' }}
                      {...(muiTheme.ids.buttonActionStyles && { ...muiTheme.ids.buttonActionStyles.titles })}
                      translate={messages.invoicePay}
                    />
                  </UILayout>
                  <UIIcon fill="white" type="selectTicketArrow" className={moduleStyles.rtlIcon} />
                </UILayout>
              </UIButton>
            )}
          </UILayout>
        </UILayout>
      </UILayout>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const contactValues = getFormValues('contact')(state)
  const passengersErrors = getFormSyncErrors('bookingForm')(state)
  const contactFormValid =
    areContactFormValuesValid(contactValues) && (!ownProps.isRingsu || !passengersErrors.passengers)

  return {
    customerRole: selectors.getCustomerRole(state),
    confirmed: state.reservation.confirmed,
    reservationId: selectors.getCurrentReservationId(state),
    currentReservation: selectors.getCurrentReservation(state),
    termsAccepted: (getFormValues('payment')(state) || {}).termsAccepted,
    paymentMethods: selectors.paymentMethodsSelector(state),
    totalPrice: selectors.getTotalPrice(state),
    locale: getLocale()(state),
    paymentStatus: getPaymentStatus(state),
    contactFormValid,
  }
}

const mapDispatchToProps = {
  changeReduxFormField: change,
  pay: actions.pay,
  goToPage,
  touchFormFields: touch,
}

const reduxFormConfig = {
  form: 'payment',
  initialValues: {
    termsAccepted: false,
  },
  destroyOnUnmount: true,
}

const PaymentContainer = compose(connect(mapStateToProps, mapDispatchToProps), reduxForm(reduxFormConfig))(Payment)

export default PaymentContainer
