import React from 'react'
// import cn from 'classnames'
import styles from './PaymentDetails.module.scss'
import messages from '../../../../consts/messages'
import { translate } from '../../../Common/Translator'

export const PaymentDetails = (props) => {
  const { reservation } = props

  return (
    <div className={styles.container}>
      <div className={styles.title}>{translate(messages.useFollowingPaymentDetails)}</div>
      <div className={styles.info}>
        <div className={styles.keys}>
          <div>{translate(messages.paymentRecipient)}</div>
          <div>{translate(messages.recipientBankAccount)}</div>
          <div>{translate(messages.recipientCIF)}</div>
          <div>{translate(messages.recipientCurrency)}</div>
          <div>{translate(messages.recipientBank)}</div>
          <div>{translate(messages.recipientBankCode)}</div>
          <div>{translate(messages.paymentDescription)}</div>
          <div>{translate(messages.paymentAmount)}</div>
        </div>
        <div className={styles.values}>
          <div>Blue Wave Corporation</div>
          <div>104343231</div>
          <div>985684</div>
          <div>USD</div>
          <div>BAC International Bank Inc</div>
          <div>BCINPAPA</div>
          <div>{translate(messages.paymentTicketNumber)} {reservation.reservationId}</div>
          <div>{reservation.paymentInfo.totalPrice.amountFormatted}</div>
        </div>
      </div>
    </div>
  )
}
