import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose, endsWith, propOr, map, filter, sum, prop, defaultTo, has, find, head } from 'ramda'

import { UIIncDecButton, UILayout } from 'bora-material-ui'
import { SelectedTicketTypeRow } from './'
import { translate } from '../../../../Common/Translator'
import LocalTravellerIDForm from '../LocalTravellerIDForm'
import messages from '../../../../../consts/messages'
import {
  getPriceLimitsFromAttributes,
  getSelectedTicketsValues,
} from '../../../../../services/pricing/reducers/selectors'
import { olderBrowser } from '../../../../../utils/browserUtils'
import { selectPassengers } from '../../../../../services/reservation/selectors'
import { TicketPromotionLine } from './TicketPromotionLine'
import { THEME } from 'bora-material-ui/themes'
import { CATEGORY_CODE, RESIDENT_POSTFIX } from '../const'
import { isSaaremaa } from '../../../../../utils/saaremaaStyleUtils'
import { LLTicketPromotionLine } from './LLTicketPromotionLine'
import { getMaritimeStyle } from '../../../../../utils/maritimeStyleUtils'
import moduleStyles from './../../../../Modal/Modals.module.scss'
import { getLocale } from '../../../../../services/user-selections'

const itemsReducer = (acc, value) => ({
  ...acc,
  [value.priceCategory]: [...(acc[value.priceCategory] ? acc[value.priceCategory] : []), value],
})

export const findPassengerPromotion = (priceCategory = '') =>
  compose(find(has('promotion')), defaultTo([]), prop(priceCategory))

export const allPassengerPromotions = (priceCategory = '') =>
  compose(filter(has('promotion')), defaultTo([]), prop(priceCategory))

const discountPriceReducer = (reservationPassengers) => (acc, ticket) => {
  const passengerPromotions = allPassengerPromotions(ticket.code)(reservationPassengers)

  if (!passengerPromotions.length) return acc

  const discounts = compose(sum, map(prop('price')))(passengerPromotions)
  const promoKey = compose(head, map(prop('promotion')))(passengerPromotions)

  const accDiscount = acc[promoKey] || 0
  return {
    [promoKey]: sum([accDiscount, discounts]),
  }
}

const xsStyles = {
  selectedTicketTypeRowWrapperTablet: { 'j-flex-center': true, width: '100%', alignItems: 'center', padding: '0 15px' },
  selectedTicketTypeRowWrapper: {
    column: getMaritimeStyle(false, true),
    'j-flex-center': true,
    width: '100%',
    alignItems: 'center',
    padding: '0 15px',
  },
  incDecButtonWrapper: { margin: '10px 0', 'j-flex-center': true },
}

const rowAndButtonsStyle = {
  ...(olderBrowser && { flexDirection: 'column' }),
}
const localIdXsStyle = { style: { padding: '20px 15px' } }

const isResidentDeckNotLocalTraveler = (ticket) =>
  ticket.resident && ticket.subType === 'DECK' && ticket.code !== CATEGORY_CODE.LOCAL_TRAVELLER

const isLocalTraveller = (code, resident) =>
  code === CATEGORY_CODE.LOCAL_TRAVELLER || endsWith(RESIDENT_POSTFIX, code) || Boolean(resident)
const isLocalTravellerCode = (code) => code === CATEGORY_CODE.LOCAL_TRAVELLER

const getTicketsCountsByType = (selectedTicketsList, editMode = false) =>
  selectedTicketsList.reduce(
    (ticketsCountByType, ticket) => {
      const isResident = editMode ? isLocalTravellerCode(ticket.code) : isLocalTraveller(ticket.code, ticket.resident)
      const type = isResident ? 'resident' : 'common'
      return { ...ticketsCountByType, [type]: ticketsCountByType[type] + ticket.count }
    },
    { resident: 0, common: 0 }
  )

const SelectedTicketsMapper = (props, { intl }) => {
  const {
    changeItemQtty,
    maxValues,
    tickets,
    editMode,
    ticketsCount,
    ticketsCountByType,
    passengerTickets,
    disabled,
    reservationPassengers,
    locale,
    maxPriceCategoryQuantities,
  } = props

  let xsStyleObj = xsStyles.selectedTicketTypeRowWrapper
  let xsIncDecButtonObj = xsStyles.incDecButtonWrapper

  if (editMode) {
    xsStyleObj = {
      ...xsStyleObj,
      style: {
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
      },
    }

    xsIncDecButtonObj = {
      ...xsIncDecButtonObj,
      style: {
        flexBasis: '30%',
        paddingTop: '15px',
      },
    }
  }

  const getCountForOtherCodes = (currentCode) =>
    compose(
      sum,
      map(prop('count')),
      filter(({ code }) =>
        currentCode === CATEGORY_CODE.LOCAL_TRAVELLER ? !code.endsWith(RESIDENT_POSTFIX) : code !== currentCode
      ),
      filter(({ code }) => code !== CATEGORY_CODE.LOCAL_TRAVELLER)
    )

  const regularTicketRows = tickets.filter(
    (ticket) => (!isResidentDeckNotLocalTraveler(ticket) || isSaaremaa) && propOr(0, 'count', ticket) > 0
  )

  const promoAccDiscount = regularTicketRows.reduce(discountPriceReducer(reservationPassengers), {})

  const ticketRow = (ticket, index) => {
    const selectAmount = (count, type = 'amount') => {
      changeItemQtty({
        ...ticket,
        count,
        type,
      })
    }
    const otherPassengerTickets = passengerTickets.filter((passengerTicket) => ticket.code !== passengerTicket.code)
    const passengerTicketsCount = otherPassengerTickets.reduce((count, ticket) => count + ticket.count, 0)
    const passengerPromotion = findPassengerPromotion(ticket.code)(reservationPassengers)

    const lastOne = regularTicketRows.length && passengerPromotion && index + 1 === regularTicketRows.length

    const { title, translationId, code, price, currency, count, inventoryClass = 'DECK', resident } = ticket
    const isLocalTravellerTicket = isLocalTraveller(code, resident)
    const ticketTitle =
      isLocalTravellerTicket && window.brandProps.theme !== THEME.SAAREMAA
        ? translate(messages.localTravellerCategory)
        : title || translate(translationId)
    const clearAll =
      window.brandProps.theme !== THEME.SAAREMAA && !isLocalTravellerTicket && (() => selectAmount(0, 'confirm'))

    const otherTypeAmount = getCountForOtherCodes(code)(tickets)
    const maxReservesValue = maxValues[`${inventoryClass}_reserves`] || 0
    const maxCommonValue = maxValues[inventoryClass] || 0

    const maxValueFromAttributes = maxPriceCategoryQuantities[code]
    const maxValueFromInventories = Math.max(
      (isLocalTravellerTicket ? maxCommonValue + maxReservesValue : maxCommonValue) - otherTypeAmount,
      count
    )
    const maxValue =
      typeof maxValueFromAttributes === 'number' && maxValueFromAttributes < maxValueFromInventories
        ? maxValueFromAttributes
        : maxValueFromInventories

    return (
      <UILayout
        column
        key={code}
        margin={getMaritimeStyle('0', '25px 0px 0px 0px')}
        sml={{ center: true }}
        data-testid="one-selected-ticket"
      >
        <UILayout
          data-testid="row-and-buttons"
          style={rowAndButtonsStyle}
          shrink="1"
          className={moduleStyles.direction}
          sml={xsStyles.selectedTicketTypeRowWrapperTablet}
          sms={xsStyleObj}
        >
          <SelectedTicketTypeRow
            title={ticketTitle}
            locale={locale}
            price={price}
            clearAmount={clearAll}
            currencyCode={currency}
            editMode={editMode}
            isLocalTraveller={isLocalTraveller}
          />

          <UILayout shrink="10" data-test="buttons-inc-dec" center sms={xsIncDecButtonObj}>
            <UIIncDecButton
              disabled={disabled}
              initValue={count}
              select={selectAmount}
              maxValue={maxValue}
              maxReservesValue={maxReservesValue}
              editMode={editMode}
              ticketsCount={ticketsCount}
              isResident={isLocalTravellerTicket}
              ticketsCountByType={ticketsCountByType}
              maxAvailableMessage={intl.formatMessage(messages.maxAvailable)}
              passengerTicketsCount={passengerTicketsCount}
              disabledInput={
                window.brandProps.muiTheme.ids.IncDecButton && window.brandProps.muiTheme.ids.IncDecButton.disabledInput
              }
            />
          </UILayout>
        </UILayout>
        <UILayout
          display-if={isLocalTravellerTicket && window.brandProps.theme !== THEME.SAAREMAA}
          data-test="local-incentive"
          margin="0"
          sml={localIdXsStyle}
        >
          <LocalTravellerIDForm ticket={ticket} editMode={editMode} />
        </UILayout>
        <TicketPromotionLine
          display-if={passengerPromotion && window.brandProps.theme !== THEME.LIINILAEVAD}
          passenger={passengerPromotion}
        />
        <LLTicketPromotionLine
          display-if={passengerPromotion && window.brandProps.theme === THEME.LIINILAEVAD}
          passenger={passengerPromotion}
          last={lastOne}
          promoDiscountSummary={promoAccDiscount}
        />
      </UILayout>
    )
  }

  return regularTicketRows.map(ticketRow)
}

SelectedTicketsMapper.propTypes = {
  changeItemQtty: PropTypes.func.isRequired,
  maxValues: PropTypes.any.isRequired,
  tickets: PropTypes.array,
  reservationPassengers: PropTypes.object,
  editMode: PropTypes.bool,
  ticketsCount: PropTypes.number.isRequired,
  ticketsCountByType: PropTypes.object.isRequired,
}

SelectedTicketsMapper.contextTypes = {
  intl: PropTypes.object,
}

SelectedTicketsMapper.defaultProps = {
  editMode: false,
  tickets: [],
}

const mapStateToProps = (state, { tickets = [], editMode = false }) => {
  const ticketsFormValues = getSelectedTicketsValues(state)
  const reservationPassengers = selectPassengers(state) || []
  const passengerTickets = Object.keys(ticketsFormValues)
    .filter((key) => ticketsFormValues[key].inventoryClass === 'DECK')
    .map((key) => ticketsFormValues[key])
    .filter((ticket) => typeof ticket === 'object' && ticket.code !== 'CHARTER')
  const locale = getLocale(state)

  const maxPriceCategoryQuantities = getPriceLimitsFromAttributes(state)

  return {
    passengerTickets,
    locale,
    maxPriceCategoryQuantities,
    ticketsCountByType: getTicketsCountsByType(tickets, editMode),
    reservationPassengers: reservationPassengers.reduce(itemsReducer, {}),
  }
}

export default compose(connect(mapStateToProps, null), React.memo)(SelectedTicketsMapper)
