/* eslint-disable react/style-prop-object,prefer-destructuring */
import React from 'react'
import { compose, pathOr, head, length } from 'ramda'
import PropTypes from 'prop-types'
import { UILayout, UIDivider, UIIcon } from 'bora-material-ui'

import { SumRow } from './Layout'

import messages from '../../../../../../../consts/messages'

import TripTicket from './TripTicket'

import { EditModeContext } from '../../../../../../MyTicket/containers/MyTicketEditMode'
import TotalPrice from '../TotalPrice'
import { THEME } from 'bora-material-ui/themes'
import { translate } from '../../../../../../Common/Translator'
import { getMaritimeStyle, isMaritime, isBluewave, getBluewaveStyle } from '../../../../../../../utils/maritimeStyleUtils'
import { getStyle, isLiinilaevad } from '../../../../../../../utils/liinilaevadThemeUtils'
import classes from './BaseModule.module.scss'
import { connect } from 'react-redux'
import { isRoundTrip as getIsRoundTrip } from '../../../../../../../services/user-selections'
import TicketRowInfo from './TicketRowInfo'
import OnlineChargesInfoIcon from './OnlineChargesInfoIcon'

const xsStyleSum = { width: '100%', padding: '10px 0' }
const xsStyleSumWrapper = window.brandProps.theme === THEME.SAAREMAA ? { style: { 'flex-direction': 'inherit' } } : {}
const xsStyleTotalWrapper = window.brandProps.theme === THEME.SAAREMAA ? { style: { width: '100%', padding: '0' } } : {}
const xsStyleTicketsWrap = { style: { borderBottom: '0' }, column: true }

const getRouteCode = compose(pathOr('', ['sailRefs', 0, 'route']), head)

const getBorderStyle = () => {
  if (isBluewave) {
    return '1px solid #54BCF0'
  } else if (isMaritime) {
    return '1px solid #C1CADE'
  } else {
    return 'solid 1px #bfc3c7'
  }
}

const styles = {
  borderBottom: { borderBottom: getBorderStyle() },
  borderTop: { borderTop: '1px solid #bfc3c7' },
  column: { flexDirection: 'column' },
}

class TicketsInfo extends React.Component {
  render() {
    const { intl } = this.context

    const {
      reservation,
      onEdit,
      onDelete,
      trips,
      userNeedToPayAfterEdit,
      isLaaksaare = false,
      outOfBookingBoundary = false,
      isRoundTrip,
    } = this.props

    const [tripThere, tripBack] = trips || [{}, {}]
    const { paymentInfo = {}, vehicles = [], sailPackages = [] } = reservation || {}
    const {
      amount,
      currency,
      taxAmount,
      amountWithoutTaxes,
      feeAmount = 0,
      feeAmountWithoutTaxes = 0,
      feeTaxAmount = 0,
    } = paymentInfo.totalPrice || {}

    const routeCode = getRouteCode(sailPackages)

    const shouldHideActions = routeCode && routeCode.includes('TAL-AEG-TAL') && length(sailPackages) > 1
    const tax = (window.brandProps.backendConfig && window.brandProps.backendConfig.taxAmount) || 20

    const onlineChargesHeaderLabels = [
      translate(messages.descriptionLabel),
      translate(messages.ticketQtty),
      translate(messages.ticketPrice),
      translate(messages.ticketVat),
      translate(messages.ticketSubtotal),
    ]

    const onlineChargesRowLabels = [
      { label: translate(messages.onlineChargesLabel), AfterLabelComponent: OnlineChargesInfoIcon },
      { label: '1' },
      { label: feeAmountWithoutTaxes.toFixed(2) },
      { label: feeTaxAmount.toFixed(2) },
      { label: feeAmount.toFixed(2) },
    ]

    return (
      <EditModeContext.Consumer>
        {(editMode) => (
          <UILayout className={classes.formDirection} column>
            <UILayout
              padding="22px 0 17px 0"
              style={{ ...styles.borderBottom, ...getMaritimeStyle(styles.column, {}) }}
              sm={xsStyleTicketsWrap}
            >
              <TripTicket
                display-if={tripThere}
                trip={tripThere}
                dataTest="ticket-one"
                onEdit={onEdit}
                width={!tripBack && editMode ? '100%' : '50%'}
                onDelete={onDelete}
                vehicles={vehicles}
                editMode={editMode}
                isTwoWayTrip={tripBack}
                userNeedToPayAfterEdit={userNeedToPayAfterEdit}
                shouldHideActions={shouldHideActions}
                showBorderBottomForMobile={false}
              />
              {isMaritime ? (
                <UILayout center>
                  <UIDivider
                    display-if={tripBack}
                    vertical
                    width="100%"
                    margin="0 15px 0 0"
                    height="0.5px"
                    bgColor="#C1CADE"
                    shrink="1"
                    visible
                  />
                  <UIIcon display-if={tripBack} fill="#C1CADE" type="iconReturnRoute" />
                  <UIDivider
                    display-if={tripBack}
                    vertical
                    width="100%"
                    margin="0 0 0 15px"
                    height="0.5px"
                    bgColor="#C1CADE"
                    shrink="1"
                    visible
                  />
                </UILayout>
              ) : (
                <UIDivider
                  display-if={tripBack}
                  vertical
                  width="1px"
                  margin="0 24px"
                  height="inherit"
                  bgColor="#C1CADE"
                  shrink="0"
                  visible
                />
              )}
              <TripTicket
                display-if={tripBack && isRoundTrip}
                trip={tripBack}
                dataTest="ticket-two"
                onEdit={onEdit}
                onDelete={onDelete}
                vehicles={vehicles}
                editMode={editMode}
                isTwoWayTrip={tripBack}
                userNeedToPayAfterEdit={userNeedToPayAfterEdit}
                shouldHideActions={shouldHideActions}
                showBorderBottomForMobile={!isMaritime}
              />
            </UILayout>

            <UILayout
              display-if={isMaritime && !isBluewave}
              column
              padding="22px 0"
              style={{ borderBottom: getBluewaveStyle('1px solid #54BCF0', '1px solid #c1cade') }}
              xs={{ borderTop: getBluewaveStyle('1px solid #54BCF0', '1px solid #c1cade') }}
            >
              <UILayout column>
                <TicketRowInfo isHeader items={onlineChargesHeaderLabels} />
                <TicketRowInfo useInitialValues items={onlineChargesRowLabels} />
              </UILayout>
            </UILayout>

            <UILayout
              data-test="total-sum"
              j-flex-start={!tripBack}
              j-flex-end={getStyle(false, tripBack)}
              display-if={!editMode}
              smm={xsStyleSumWrapper}
              xs={getMaritimeStyle({ flexDirection: 'column' })}
            >
              <UILayout
                column
                display-if={!isMaritime}
                width={window.brandProps.theme === THEME.SAAREMAA ? '51%' : '50%'}
                padding={getMaritimeStyle(
                  '20px 0 0 0',
                  getStyle('22px 40px 0 0', `0 22px 0 ${tripBack ? '22px' : '0px'}`)
                )}
                sm={xsStyleSum}
              >
                <SumRow
                  label={translate(messages.subTotalWithTax, { tax })}
                  sum={amountWithoutTaxes}
                  currencyCode={currency}
                />
                <SumRow label={translate(messages.tax, { tax })} sum={taxAmount} currencyCode={currency} />
                <SumRow
                  subTotal={true}
                  label={intl.formatMessage(messages.subTotal)}
                  sum={amount}
                  currencyCode={currency}
                />
              </UILayout>
              <UILayout
                width={getMaritimeStyle('100%', '50%')}
                row
                padding={getMaritimeStyle('40px 0 5px 0', '20px 25px 15px 25px')}
                display-if={!editMode && (window.brandProps.theme === THEME.SAAREMAA || isMaritime || isLiinilaevad)}
                smm={xsStyleTotalWrapper}
                xs={getMaritimeStyle({ width: '100%' })}
              >
                <TotalPrice isConfirmPage />
              </UILayout>
            </UILayout>

            <UILayout display-if={isLaaksaare && !outOfBookingBoundary} column width="50%" sm={xsStyleSum}>
              <UILayout style={styles.borderTop} padding="11px 22px 11px 0">
                <TotalPrice reservationFee={true} />
              </UILayout>
            </UILayout>
          </UILayout>
        )}
      </EditModeContext.Consumer>
    )
  }
}

TicketsInfo.propTypes = {
  reservation: PropTypes.object.isRequired,
  trips: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  userNeedToPayAfterEdit: PropTypes.bool,
}

TicketsInfo.defaultProps = {
  userNeedToPayAfterEdit: false,
}

TicketsInfo.contextTypes = {
  intl: PropTypes.any,
}

export default connect(
  (state) => ({
    isRoundTrip: getIsRoundTrip(state),
  }),
  null
)(TicketsInfo)
