import React from 'react'
import { UIButton, UIIcon, UILayout, UIText } from 'bora-material-ui'
import UIElements from '../../../../consts/UIElements'
import styled from 'styled-components'
import messages from '../../../../consts/messages'
import { getMaritimeStyle, isMaritime } from '../../../../utils/maritimeStyleUtils'
import moduleStyles from '../../../Modal/Modals.module.scss'

const ButtonLayout = styled(UILayout)`
  box-sizing: border-box;
  align-items: center;
  justify-content: space-around;
  padding: 0 5px;
`

const xs = { justifyContent: 'center' }
const buttonXs = { width: getMaritimeStyle('150px', '100%') }

const style = getComputedStyle(document.body)
const colorSecondaryBase = style.getPropertyValue('--color-secondary-base')
const fontSizeStrong = style.getPropertyValue('--fs-base-strong')

export const NextPassengerDataButton = ({ action, enabled }) => {
  return (
    <UILayout j-flex-end className={moduleStyles.direction}>
      <UIButton
        click={() => action({ index: 0 })}
        background={colorSecondaryBase}
        color="#fff"
        width="160px"
        maxWidth="200px"
        height="40px"
        borderRadius="5px"
        padding="0"
        row
        j-flex-center
        xs={buttonXs}
        disabled={!enabled}
      >
        <ButtonLayout height="100%" xs={xs} className={moduleStyles.direction}>
          {isMaritime ? (
            <UIText align="left" size={fontSizeStrong} translate={messages.saveEdit} />
          ) : (
            <UIText align="left" size={fontSizeStrong} translate={messages.passengerInfoSaveNext} />
          )}
          <UIIcon fill="white" type="selectTicketArrow" className={moduleStyles.rtlIcon} />
        </ButtonLayout>
      </UIButton>
    </UILayout>
  )
}
